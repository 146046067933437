* {
  /* font-family: Poppins-Regular, sans-serif !important; */
  font-family: "Montserrat", sans-serif !important;
}
.wrapper-billet {
  padding-top: 5%;
  /* font-family: 'Montserrat', sans-serif; */
  background: linear-gradient(180deg,#3babbc,#325f98);
}

.label-info {
  background-color: lightblue;
  padding: 20px;
  font-size: 16px;
  font-weight: bold;
  margin: 10px 30px;
}

.icon-pix {
  background-image: url('../../assets/images/pix.svg');
  width: 20px;
  height: 20px;
}

.header-billet {
  background-color: #edeae3!important;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-print-color-adjust: exact;
}
.column-right {
  display: flex;
  flex-direction: column;
  float: right;
  text-align: right;
}
.text-bold {
  font-weight: bold !important;
}
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.card-billet {
  background-color: #ffff;
  /* padding: 10px 40px 40px 40px; */
  border-radius: 2px;
}
.display-15 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
}
.sizefull, .w-full {
  width: 100%;
}
.last-section {
  margin-top: 0;
  width: 100%!important;
  border-radius: 0;
}
.wrap-contact100 {
  width: 70%;
  border-radius: 10px;
  overflow: hidden;
  padding: 42px 55px 45px;
  margin-top: 1rem;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-t-45 {
  padding-top: 45px;
}
.m-r-15 {
  margin-right: 15px !important;
}
.m-l-15 {
  margin-left: 15px !important;
}
.last-section span {
  color: #fefefe!important
}
.text-last-section {
  opacity: .8;
  line-height: 1.5;
}
.p-b-5 {
  padding-bottom: 5px;
}
hr {
  box-sizing: content-box !important;
  height: 0 !important;
  overflow: visible !important;
}
.text-last-section {
  opacity: .8;
  line-height: 1.5;
}
.last-section hr {
  border-bottom: .7px solid #aadcff;
}
.rounded-logo {
  position: relative;
  left: 0;
  right: 0;
  width: 100px;
  margin-left: auto;
  margin-right: auto;
  top: -2.8rem;
  background: linear-gradient(180deg,#325f98, #3babbc);
  border-radius: 50%;
}

.text-button {
  background-color: #325F98;
  border-color: #325F98;
  color: #ffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  font-family: Poppins-Medium;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
}

.text-button:hover {
  background-color: #325F98;
  border-color: #325F98;
  color: #ffff;
}

.ant-message {
  z-index: 9999999;
}

.box-item h3 {
  font-size: 21px;
}

@media (max-width: 1400px) {
  .wrapper-billet {
    padding-top: 6%;
  }
}
@media (max-width: 1000px) {
  .wrapper-billet {
    padding-top: 8%;
  }
}
@media (max-width: 700px) {
  .wrapper-billet {
    padding-top: 11%;
  }
}
@media (max-width: 500px) {
  .wrapper-billet {
    padding-top: 13%;
  }
}


